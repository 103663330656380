import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  LoadingData,
  DeleteModal,
  MyDatePicker,
} from "../../../components";
import { getScheduleData, updateScheduleData } from "./modules/actions";
import { connect } from "react-redux";
import { schedulerColor } from "../../../config";
import { alert } from "../../../utilities";
import moment from "moment";
import Backdrop from "../../../components/Backdrop";
import Modal from "../../../components/Modal";
import TimeZone from "./Timezone";
import { settingsServices } from "./modules/services";
import "./setting.css";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import SaveSection from "./SaveSection";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  CardContent,
  MenuItem,
  Typography,
  FormControl,
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  OutlinedInput,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ViewBreakModal from "./ViewBreakModal";
import NewSaveSection from "./NewSaveSection";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  HeadCell: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#232056",
    marginLeft: 10,
  },
  buttonMain: {
    textAlign: "right",
    fontWeight: 700,
  },
  button: {
    background: "#005F73",
    borderRadius: 8,
    color: "white",
    padding: 10,

    "&:hover": {
      background: "#005F73",
      borderRadius: 8,
    },
  },
  flex: {
    display: "flex",
    margin: "20px",
  },
  Heading: {
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  HeadingValue: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "36px",
  },
  card: {
    //   margin: '15px 0px 0px 0px',
    borderRadius: 8,
    background: "#FFFFFF",
    //   boxShadow: '0 0 0.5em #E2E2E2',
    boxShadow: "0 12.5214px 16.9039px rgb(0 0 0 / 5%)",
  },
  scheduleCard: {
    background: "rgba(240, 243, 245, 0.98)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: 16,
    gap: 5,
    display: "flex",
    justifyContent: "space-between",
  },
  divWdth: {
    background: "#fff",
    border: "1px solid rgba(161, 161, 161, 0.5)",
    width: 90,
    borderRadius: "10px",
    cursor: "pointer",
    height: "150px",
  },
  divWdths: {
    background: "#4D72F8",
    cursor: "pointer",
    color: "white",
    marginTop: 26,
    padding: 7,
    width: "122%",
    marginLeft: -8,
    height: 80,
  },
  time: {
    marginTop: 10,
    fontFamily: "Calibri",
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
    marginTop: -12,
  },
  viewSection: {
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
});

export function fetchSCSettingsScheduling() {
  window.Scheduling.getScheduleData();
}

class Scheduling extends Component {
  constructor(props) {
    super(props);
    window.Scheduling = this;
    this.state = {
      disable: true,
      isWeek: false,
      flageed: [
        { value: 1, label: "Flagged" },
        { value: 0, label: "Blocked" },
      ],
      applyToAll: 0,
      workschedule: false,
      workRestriction: false,
      workRestriction1: false,
      Reminders: false,
      breakss: false,
      alertsAndNotification: false,
      emoji: 1,
      anchorEl: null,
      data: {},
      allBreaks: [],
      addBreaksData: [],
      temArray: [],
      location_timezone: "",
      dayChange: "",
      deleterowId: "",
      break_start_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      break_end_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      isBreakUpdate: 0,
      viewModalOpen: false,
      mark_employee_as_late_flag_issue1: false,
      mark_employee_as_late_flag_issue: true,
      automatically_end_breaks_employees_flag_issue: true,
      automatically_end_breaks_employees_flag_issue1: false,
      automatically_clock_out_employees_flag_issue: true,
      automatically_clock_out_employees_flag_issue1: false,
      isMustAknowledge_comment: false,
      BlockDropdownData: [
        { value: 2, label: "Block" },
        { value: 1, label: "Flag" },
      ],
      PaidDropdownData: [
        { value: 1, label: "Paid" },
        { value: 2, label: "Unpaid" },
      ],
      MandatoryDropdownData: [
        { value: 1, label: "Mandatory" },
        { value: 2, label: "Optional" },
      ],
      ScheduleDay: [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 0, label: "Sunday" },
      ],
      weekDaysTimingsData: [
        {
          day: "Monday",
          schedule_start_day: 1,
          schedule_day_start_time: "",
          schedule_day_end_time: "",
        },
        // {
        //     "day": "Tuesday",
        //     "open": "",
        //     "close": ""
        // },
        // {
        //     "day": "Wednesday",
        //     "open": "",
        //     "close": ""
        // },
        // {
        //     "day": "Thursday",
        //     "open": "",
        //     "close": "",
        // },
        // {
        //     "day": "Friday",
        //     "open": "",
        //     "close": "",
        // },
        // {
        //     "day": "Saturday",
        //     "open": "",
        //     "close": "",
        // },
        // {
        //     "day": "Sunday",
        //     "open": "",
        //     "close": ""
        // }
      ],
      weekDaysTimingsData1: [
        {
          day: "Monday",
          schedule_start_day: 1,
          schedule_day_start_time: "",
          schedule_day_end_time: "",
        },
        // {
        //     "day": "Tuesday",
        //     "open": "",
        //     "close": ""
        // },
        // {
        //     "day": "Wednesday",
        //     "open": "",
        //     "close": ""
        // },
        // {
        //     "day": "Thursday",
        //     "open": "",
        //     "close": "",
        // },
        // {
        //     "day": "Friday",
        //     "open": "",
        //     "close": "",
        // },
        // {
        //     "day": "Saturday",
        //     "open": "",
        //     "close": "",
        // },
        // {
        //     "day": "Sunday",
        //     "open": "",
        //     "close": ""
        // }
      ],
      TimeZonedropDown: this.getTimezoneNames(),
      ScheduleTime: [
        { value: "00:00:00", label: "12 AM" },
        { value: "01:00:00", label: "1 AM" },
        { value: "02:00:00", label: "2 AM" },
        { value: "03:00:00", label: "3 AM" },
        { value: "04:00:00", label: "4 AM" },
        { value: "05:00:00", label: "5 AM" },
        { value: "06:00:00", label: "6 AM" },
        { value: "07:00:00", label: "7 AM" },
        { value: "08:00:00", label: "8 AM" },
        { value: "09:00:00", label: "9 AM" },
        { value: "10:00:00", label: "10 AM" },
        { value: "11:00:00", label: "11 AM" },
        { value: "12:00:00", label: "12 PM" },
        { value: "13:00:00", label: "1 PM" },
        { value: "14:00:00", label: "2 PM" },
        { value: "15:00:00", label: "3 PM" },
        { value: "16:00:00", label: "4 PM" },
        { value: "17:00:00", label: "5 PM" },
        { value: "18:00:00", label: "6 PM" },
        { value: "19:00:00", label: "7 PM" },
        { value: "20:00:00", label: "8 PM" },
        { value: "21:00:00", label: "9 PM" },
        { value: "22:00:00", label: "10 PM" },
        { value: "23:00:00", label: "11 PM" },
      ],
    };
  }

  toggleDisable = () => {
    if (this.state.breakss === true) {
      this.setState((prevState) => ({
        disable: !prevState.disable,
      }));
    }
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleViewModalOpen = () => {
    this.setState({
      viewModalOpen: true,
    });
  };

  showPreviewBreak = () => {
    if (this.state.breakss === true) {
      this.handleViewModalOpen();
      // this.setState({
      //     isLoading: true
      // })
      settingsServices
        .previewBreaks(this.props.scheduler_lid)
        .then((response) => {
          if (response.data.success === 2) {
            alert.error(response.data.message);
          } else {
            this.setState({
              previewBreakData: response.data.PayrollData,
              isLoading: false,
            });

            // alert.success(response.data.message)
          }
        });
    }
  };

  onDeleteBreak = (id) => {
    settingsServices.deleteBreaks(id).then((response) => {
      if (response.data.success === 2) {
        alert.error(response.data.message);
      } else {
        this.setState({
          previewBreakData: response.data.PayrollData,
        });
        alert.success(response.data.message);
      }
    });
  };

  handleViewModalClose = () => {
    this.setState({
      viewModalOpen: false,
    });
  };

  getTimezoneNames = () => {
    let names = moment.tz.names();
    let final_array = [];

    names.forEach((name) => {
      final_array = final_array.concat({
        label: name,
        value: name,
      });
    });

    return final_array;
  };

  componentDidMount = () => {
    if (this.props.permissionsData.scheduler_settings) {
      this.getScheduleData();

      var timeArray = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          // for(let second = 0; second < 60; second += 1){
          //     var id = moment({ hour }).format('HH:mm');
          //     var name = moment({ minute }).format('HH:mm:');
          //     var secondss = moment({ second }).format('ss')
          //     timeArray.push({ id, name, secondss });
          // }
          var id = moment({ hour, minute }).format("HH:mm:ss");
          var name = moment({ hour, minute }).format("hh:mmA");
          timeArray.push({ id, name });
        }
      }
      this.setState({ timeArray });
    }
  };

  onChangeSetAsAllWeeks = (e) => {
    this.setState({ isWeek: !this.state.isWeek });
    if (e.target.checked) {
      var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
        const openValue =
          this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
        const closeValue =
          this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
        var cloneItem = item;
        cloneItem["schedule_day_start_time"] = openValue;
        cloneItem["schedule_day_end_time"] = closeValue;
        return cloneItem;
      });
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    } else {
      var newRoutes = this.state.weekDaysTimingsData1.map((item, myIndex) => {
        const openValue =
          this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
        const closeValue =
          this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
        var cloneItem = item;
        cloneItem["schedule_day_start_time"] = item.schedule_day_start_time;
        cloneItem["schedule_day_end_time"] = item.schedule_day_end_time;
        if (myIndex === 0) {
          cloneItem["schedule_day_start_time"] = openValue;
          cloneItem["schedule_day_end_time"] = closeValue;
        }
        return cloneItem;
      });
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    }
  };

  handleChangeWeekTiming = (e, isOpen, index) => {
    if (this.state.isWeek === true) {
      var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
        var cloneItem = item;
        // if (index === myIndex) {
        cloneItem[isOpen] = e.value;
        // }
        return cloneItem;
      });
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    } else {
      var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
        var cloneItem = item;
        if (index === myIndex) {
          cloneItem[isOpen] = e.value;
        }
        return cloneItem;
      });
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    }
  };

  getScheduleData = () => {
    this.setState({
      isLoading: true,
    });
    var lid = this.props.scheduler_lid;
    this.props.getScheduleData(lid);
  };

  handleChangeBreaksCheckbox = (e, index) => {
    var allBreaks = this.state.allBreaks;
    var state = e.target.name;
    var value = e.target.value;

    allBreaks[index][state] = allBreaks[index][state] ? 0 : 1;
    allBreaks[index].add_edit_status =
      allBreaks[index] && allBreaks[index]._id ? "edit" : "add";
    if (value) {
      this.setState((prevState) => ({
        allBreaks: JSON.parse(JSON.stringify(this.state.allBreaks)),
        isBreakUpdate: 1,
        data: {
          ...prevState.data,
          active_breakrule_id: value,
        },
        isBreakUpdate: 1,
      }));
    }
  };

  handleChangeBreaksValue = (e, index) => {
    var allBreaks = this.state.allBreaks;
    var state = e.target.name;
    var value = e.target.value;
    allBreaks[index][state] = value;
    allBreaks[index].add_edit_status =
      allBreaks[index] && allBreaks[index]._id ? "edit" : "add";
    this.setState({
      allBreaks: JSON.parse(JSON.stringify(this.state.allBreaks)),
      isBreakUpdate: 1,
    });
  };

  addNewRow = () => {
    this.setState((prevState) => ({
      addBreaksData: [
        ...prevState.addBreaksData,
        {
          status: 0,
          break_minutes: "0",
          is_paid: "0",
          break_hours: "0",
          is_mendatory: "0",
        },
      ],
    }));
  };

  handleChangeNewBreaksValue = (e, index) => {
    var addBreaksData = this.state.addBreaksData;
    var state = e.target.name;
    var value = e.target.value;
    addBreaksData[index][state] = value;
    addBreaksData[index].add_edit_status = "add";
    this.setState({
      addBreaksData: JSON.parse(JSON.stringify(this.state.addBreaksData)),
      isBreakUpdate: 1,
    });
  };

  deleteRow = (data) => {
    this.setState({
      deleteModal: true,
      deleterowId: data._id,
    });
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    settingsServices
      .deleteBreakData(this.state.deleterowId)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            allBreaks: this.state.allBreaks.filter(
              (item) => item._id !== this.state.deleterowId
            ),
          });
          alert.success(response.data.message);
        }
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
      if (this.props.LocationDetaildata.workSchedule) {
        const newWeekDaysTimingsData = [
          ...this.props.LocationDetaildata.workSchedule,
        ].map((item) => {
          var cloneItem = item;
          var schedule_day_start_time = moment(item.schedule_day_start_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");

          var schedule_day_end_time = moment(item.schedule_day_end_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          cloneItem["schedule_day_start_time"] = schedule_day_start_time;
          cloneItem["schedule_day_end_time"] = schedule_day_end_time;
          return cloneItem;
        });
        this.setState({
          weekDaysTimingsData: newWeekDaysTimingsData,
          weekDaysTimingsData1: newWeekDaysTimingsData,
        });
      } else {
        const newWeekDaysTimingsData = this.props.LocationDetaildata?.map(
          (item) => {
            var cloneItem = item;
            var schedule_day_start_time = moment(item.schedule_day_start_time)
              .tz(item.schedule_time_zone)
              .format("HH:mm");

            var schedule_day_end_time = moment(item.schedule_day_end_time)
              .tz(item.schedule_time_zone)
              .format("HH:mm");
            cloneItem["schedule_day_start_time"] = schedule_day_start_time;
            cloneItem["schedule_day_end_time"] = schedule_day_end_time;
            return cloneItem;
          }
        );
        this.setState({
          weekDaysTimingsData1: newWeekDaysTimingsData,
        });
      }

      this.setState(
        {
          isLoading: false,
          location_timezone: this.props.LocationDetaildata.location_timezone,
          data: this.props.LocationDetaildata.settings,
          dayChange: this.props.LocationDetaildata?.settings?.enforce_start_day,
        },
        () => {
          console.log("xkjskjnkcdc", this.state.data);
        }
      );
    }
  }

  handleChangeData = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    if (checked === true) {
      this.setState({
        mark_employee_as_late_flag_issue: false,
        automatically_end_breaks_employees: false,
      });
    } else {
      this.setState({
        mark_employee_as_late_flag_issue: true,
        mark_employee_as_late_flag_issue1: false,
        automatically_end_breaks_employees_flag_issue1: false,
      });
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDataTwo = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    if (checked === true) {
      this.setState({
        automatically_end_breaks_employees: false,
      });
    } else {
      this.setState({
        automatically_end_breaks_employees: true,
        automatically_end_breaks_employees_flag_issue1: false,
      });
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDataThree = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    if (checked === true) {
      this.setState({
        automatically_clock_out_employees_flag_issue: false,
      });
    } else {
      this.setState({
        automatically_clock_out_employees_flag_issue: true,
        automatically_clock_out_employees_flag_issue1: false,
      });
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDataNewOne = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    // if (checked === true) {
    //   this.setState({
    //     mark_employee_as_late_flag_issue1: true,
    //   });
    // } else {
    //   this.setState({
    //     mark_employee_as_late_flag_issue1: false,
    //   });
    // }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDataNewTwo = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    // if (checked === true) {
    //   this.setState({
    //      automatically_end_breaks_employees_flag_issue1: true,
    //   });
    // } else {
    //   this.setState({
    //      automatically_end_breaks_employees_flag_issue1: false,
    //   });
    // }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDataNewThree = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    // if (checked === true) {
    //   this.setState({
    //     automatically_clock_out_employees_flag_issue1: true,
    //   });
    // } else {
    //   this.setState({
    //     automatically_clock_out_employees_flag_issue1: false,
    //   });
    // }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDate = (date, state) => {
    let d = new Date(date);
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          [state]: date,
        },
      }),
      () => {}
    );
  };

  editworkRestriction = () => {
    var sendObj = {
      early_clock_in_before_shift_start:
        this.state.data.early_clock_in_before_shift_start,
      early_clock_in_before_shift_start_type:
        this.state.data.early_clock_in_before_shift_start_type,
      early_clock_in_before_shift_start_minutes:
        this.state.data.early_clock_in_before_shift_start_minutes,
      automatically_clock_out_employees:
        this.state.data.automatically_clock_out_employees,
      automatically_clock_out_employees_minutes:
        this.state.data.automatically_clock_out_employees_minutes,
      automatically_clock_out_employees_flag_issue:
        this.state.data.automatically_clock_out_employees_flag_issue,

      mark_employee_as_late: this.state.data.mark_employee_as_late,
      mark_employee_as_late_minutes:
        this.state.data.mark_employee_as_late_minutes,
      mark_employee_as_late_flag_issue:
        this.state.data.mark_employee_as_late_flag_issue,
    };
    settingsServices
      .workRestriction(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            workRestriction: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editReminders = () => {
    var sendObj = {
      clock_out_reminder_before_shift_end:
        this.state.data.clock_out_reminder_before_shift_end,
      clock_out_reminder_before_shift_end_minutes:
        this.state.data.clock_out_reminder_before_shift_end_minutes,
      clock_out_reminder_after_shift_end:
        this.state.data.clock_out_reminder_after_shift_end,
      clock_out_reminder_after_shift_end_minutes:
        this.state.data.clock_out_reminder_after_shift_end_minutes,
    };
    if (
      this.state.data.clock_out_reminder_before_shift_end_minutes >
      this.state.data.clock_out_reminder_after_shift_end_minutes
    ) {
      alert.error(
        "Clock-out reminder time should be less than Automatically clock-out employee time"
      );
      return;
    } else {
      settingsServices
        .Reminders(this.props.scheduler_lid, sendObj)
        .then((response) => {
          if (response.data.success === 2) {
            alert.error(response.data.message);
          } else {
            this.setState({
              Reminders: false,
            });

            this.props.getScheduleData(this.props.scheduler_lid);
            alert.success(response.data.message);
          }
        });
    }
  };

  editBreaks = () => {
    var sendObj = {
      isbreak_enable: this.state.data.isbreak_enable,
      break_start_date: this.state.data.break_start_date,
      break_end_date: this.state.data.break_end_date,
      break_Employees_get_minutes: this.state.data.break_Employees_get_minutes,
      break_Employees_get_count: this.state.data.break_Employees_get_count,
      break_Employees_get_hour: this.state.data.break_Employees_get_hour,
      isbreak_hours_type: this.state.data.isbreak_hours_type,
      // paid_break_to_an_unpaid_break:
      //   this.state.data.paid_break_to_an_unpaid_break,
      automatically_apply_breaks_on_timecard:
        this.state.data.automatically_apply_breaks_on_timecard,
      automatically_end_breaks_employees:
        this.state.data.automatically_end_breaks_employees,
      automatically_end_breaks_employees_minutes:
        this.state.data.automatically_end_breaks_employees_minutes,
      automatically_end_breaks_employees_flag_issue:
        this.state.data.automatically_end_breaks_employees_flag_issue,
      ending_break_earlier_than_break_settings:
        this.state.data.ending_break_earlier_than_break_settings,
    };
    settingsServices
      .breaks(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            breakss: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editAlertsAndNotification = () => {
    var sendObj = {
      manager_approval_for_availability_requests:
        this.state.data.manager_approval_for_availability_requests,
      employees_create_correction_requests:
        this.state.data.employees_create_correction_requests,
      shift_feedback_after_end: this.state.data.shift_feedback_after_end,
    };
    settingsServices
      .alertsandnotification(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            alertsAndNotification: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  handleChangeSelectValue = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: value,
      },
    }));
  };

  handleChangeText = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    if (state) {
      const validMin = value.match(/^[0-9]+$/);
      if (!validMin && value !== "") {
        alert("Not a valid number");
      }
    }
    // if (this.state.data.enforce_clockout_reminder_min < this.state.data.enforce_auto_clockout_min) {
    //     alert("Clock-out reminder should be less than Automatically clock-out employee")
    // }
    // else{
    //     // value = ""
    // }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: value,
      },
    }));
  };

  handleChangeDays = (event, newValue) => {
    if (this.state.workschedule === true) {
      this.setState(
        {
          location_timezone: newValue ? newValue.value : "",
        },
        () => {}
      );
    }
  };

  // handleChangeDays = (e) => {
  //   if (this.state.workschedule === true) {
  //     this.setState(
  //       {
  //         location_timezone: e.target.value,
  //       },
  //       () => {}
  //     );
  //   }
  // };

  // onsubmitData = () => {
  //     var data = {
  //         lid: this.state.data.lid,
  //         applyToAll: this.state.applyToAll,
  //         enforce_start_day: this.state.data?.enforce_start_day,
  //         enforce_hours_start: this.state.data.enforce_hours_start,
  //         enforce_hours_end: this.state.data.enforce_hours_end,
  //         location_timezone: this.state.data.location_timezone,
  //         enforce_mark_emp_late_min: this.state.data.enforce_mark_emp_late_min,
  //         enforce_prevent_early_clockin: this.state.data.enforce_prevent_early_clockin,
  //         enforce_prevent_early_clockin_min: this.state.data.enforce_prevent_early_clockin_min,
  //         enforce_prevent_early_clockin_breaks: this.state.enforce_prevent_early_clockin_breaks,
  //         enforce_clockout_reminder: this.state.data.enforce_clockout_reminder,
  //         enforce_clockout_reminder_min: this.state.data.enforce_clockout_reminder_min,
  //         enforce_auto_clockout: this.state.data.enforce_auto_clockout,
  //         enforce_auto_clockout_min: this.state.data.enforce_auto_clockout_min,
  //         enforce_auto_breakend: this.state.data.enforce_auto_breakend,
  //         automatically_end_breaks_employees: this.state.data.automatically_end_breaks_employees,
  //         enforce_notify_forgotten_clockout_time: this.state.data.enforce_notify_forgotten_clockout_time,
  //         enforce_allow_forgotten_clockout_time: this.state.data.enforce_allow_forgotten_clockout_time,
  //         alert_notify_manager_emp_not_clock_in: this.state.data.alert_notify_manager_emp_not_clock_in,
  //         alert_notify_manager_emp_not_clock_in_min: this.state.data.alert_notify_manager_emp_not_clock_in_min,
  //         alert_manager_approval_emp_availability: this.state.data.alert_manager_approval_emp_availability,
  //         events_trades: this.state.data.events_trades
  //     }
  //     if (data.enforce_clockout_reminder_min > data.enforce_auto_clockout_min) {
  //         alert.error("Clock-out reminder time should be less than Automatically clock-out employee time");
  //         return;
  //     }
  //     this.props.updateScheduleData(data)
  // }

  handleApplyToAll = (e) => {
    this.setState({
      applyToAll: e.target.checked ? 1 : 0,
    });
  };

  rangeCalculater = (date, index) => {
    if (date === undefined || date === null) {
      return false;
    }
    const startRange =
      date.schedule_day_start_time !== ""
        ? Number(date.schedule_day_start_time.split(":")[0])
        : null;
    const endRange =
      date.schedule_day_end_time !== ""
        ? Number(date.schedule_day_end_time.split(":")[0])
        : null;
    if (startRange === null || endRange === null) {
      return false;
    }
    const inbtwnRange = index >= startRange && index <= endRange ? true : false;
    return {
      inbtwnRange: inbtwnRange,
      startIndex: {
        index: startRange,
        value: date.schedule_day_start_time,
      },
      endIndex: {
        index: endRange,
        value: date.schedule_day_end_time,
      },
    };
  };

  handlePopupModal = () => {
    if (this.state.workschedule === true) {
      this.setState({
        openModal: !this.state.openModal,
      });
    }
  };

  openEditTableModal = (value) => {
    if (value === "workSchedule") {
      this.setState({
        workschedule: true,
      });
    }
    if (value === "workRestrictions") {
      this.setState({
        workRestriction: true,
      });
    }
    if (value === "Reminders") {
      this.setState({
        Reminders: true,
      });
    }
    if (value === "Breaks") {
      this.setState({
        breakss: true,
      });
    }
    if (value === "alertsAndNotification") {
      this.setState({
        alertsAndNotification: true,
      });
    }
  };

  closeEditModal = (value) => {
    this.setState({
      location_timezone: this.props.LocationDetaildata.location_timezone,
      data: this.props.LocationDetaildata.settings,
      dayChange: this.props.LocationDetaildata?.settings?.enforce_start_day,
    });

    this.setState({
      workschedule: value === "workSchedule" ? false : this.state.workschedule,
      workRestriction:
        value === "workRestrictions" ? false : this.state.workRestriction,
      Reminders: value === "Reminders" ? false : this.state.Reminders,
      breakss: value === "Breaks" ? false : this.state.breakss,
      alertsAndNotification:
        value === "alertsAndNotification"
          ? false
          : this.state.alertsAndNotification,
    });
  };

  handleChangeDay = (e) => {
    this.setState({
      dayChange: e.target.value,
    });
  };

  handleSubmitNew = async () => {
    var tempArray = this.state.weekDaysTimingsData.map((item, index) => {
      var timeZoneSplit = this.state.location_timezone.split("|").pop();
      let initialdate = moment().format("YYYY-MM-DD");
      let start_time =
        initialdate +
        "T" +
        item.schedule_day_start_time +
        ":00" +
        timeZoneSplit;
      let datetimeA = start_time;
      let initialdateEnd = moment().format("YYYY-MM-DD");
      let end_time =
        initialdateEnd +
        "T" +
        item.schedule_day_end_time +
        ":00" +
        timeZoneSplit;
      let datetimeB = end_time;

      var splitdata = timeZoneSplit[0];
      var timezone = moment(datetimeA)
        .tz(splitdata)
        .format("YYYY-MM-DDTHH:mm:ssZ");
      var timezone_end = moment(datetimeB)
        .tz(splitdata)
        .format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc = moment(timezone).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc_end = moment(timezone_end).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      var newObj = {
        schedule_start_day: index,
        schedule_day_start_time: timezone,
        schedule_day_end_time: timezone_end,
        schedule_time_zone: this.state.location_timezone,
      };
      return newObj;
    });
    this.setState({
      temArray: tempArray,
    });
  };

  handleSubmit = async () => {
    var tempArray = this.state.weekDaysTimingsData.map((item, index) => {
      var timeZoneSplit = this.state.location_timezone.split("|").pop();
      let initialdate = moment().format("YYYY-MM-DD");
      let start_time = initialdate + "T" + item.schedule_day_start_time + ":00";

      let datetimeA = start_time;
      let initialdateEnd = moment().format("YYYY-MM-DD");
      let end_time = initialdateEnd + "T" + item.schedule_day_end_time + ":00";

      let datetimeB = end_time;

      // var splitdata = timeZoneSplit[0];
      // var timezone = moment(datetimeA).tz(splitdata).format("YYYY-MM-DDTHH:mm:ssZ");
      // var timezone_end = moment(datetimeB).tz(splitdata).format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc = moment(timezone).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc_end = moment(timezone_end).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      var newObj = {
        schedule_start_day: index,
        schedule_day_start_time: datetimeA,
        schedule_day_end_time: datetimeB,
        schedule_time_zone: this.state.location_timezone,
      };
      return newObj;
    });

    var data = {
      select_day: this.state.dayChange,
      same_for_all_days: this.state.isWeek,
      schedule_time_zone: this.state.location_timezone,
      DaysArr: tempArray,
    };
    settingsServices
      .addWorkScheduler(this.props.scheduler_lid, data)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          // this.setState({
          if (this.state.workschedule === true) {
            this.setState({
              workschedule: false,
            });
          }
          // })
          // this.props.getScheduleData(this.props.scheduler_lid,data)
          alert.success(response.data.message);
        }
      });
  };

  handleCheckboxAcknowledge = (e, item) => {
    let value = e.target.checked;
    let newarray = [...this.state.previewBreakData];
    newarray.map((itemm, index) => {
      if (item === itemm._id) {
        return (itemm.isSelected = value === true && true);
      } else {
        return (itemm.isSelected = false);
      }
    });
    // var tempCategory = this.state.questionFormteamRatingData;
    // tempCategory[index]["question_options"] = newarray;
    this.setState({
      previewBreakData: newarray,
    });
  };

  handleSubmitBreak = () => {
    var newObj = this.state.previewBreakData.filter(
      (mapp) => mapp.isSelected === true
    );
    var sendObj = {
      isbreak_enable: newObj[0].isbreak_enable,
      break_start_date: newObj[0].break_start_date,
      break_end_date: newObj[0].break_end_date,
      break_Employees_get_minutes: newObj[0].break_Employees_get_minutes,
      break_Employees_get_count: newObj[0].break_Employees_get_count,
      break_Employees_get_hour: newObj[0].break_Employees_get_hour,
      isbreak_hours_type: newObj[0].isbreak_hours_type,
      // paid_break_to_an_unpaid_break: newObj[0].paid_break_to_an_unpaid_break,
      automatically_apply_breaks_on_timecard:
        newObj[0].automatically_apply_breaks_on_timecard,
    };
    settingsServices
      .breaks(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            breakss: false,
            viewModalOpen: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  render() {
    const cardBg = "#faf9f9";
    const cardRadius = "12px";
    const { permissionsData, classes, LocationDetaildata, isLoading } =
      this.props;
    if (this.state.isLoading) return <LoadingData />;
    if (isLoading) return <LoadingData />;
    console.log("kdhvkf", this.state.data);

    return permissionsData.scheduler_settings ? (
      <Grid container spacing={2} className={classes.root}>
        {this.state.openModal && (
          <div>
            <Backdrop handlePopupModal={this.handlePopupModal} />
            <Modal title="TimeZone" handlePopupModal={this.handlePopupModal}>
              <div style={{ width: 850 }}>
                <TimeZone
                  isWeek={this.state.isWeek}
                  onChangeSetAsAllWeeks={this.onChangeSetAsAllWeeks}
                  handlePopupModal={this.handlePopupModal}
                  daysData={this.state.weekDaysTimingsData}
                  handleChangeWeekTiming={this.handleChangeWeekTiming}
                  handleSubmit={this.handleSubmitNew}
                />
              </div>
            </Modal>
          </div>
        )}
        {/* <Grid item md={12}
                        justifyContent="space-between"
                        container >
                        <Grid></Grid>
                        <Button
                            className={classes.button}
                            onClick={this.onsubmitData}
                        >
                            Save Changes
                        </Button> */}
        {/* <ApplyToAllLocation
                            applyToAll={this.state.applyToAll}
                            handleApplyToAll={this.handleApplyToAll}
                            handleSave={this.onsubmitData}
                        />
                         <Typography style={{marginLeft:22,fontSize:10,lineHeight:0}}><span style={{color:"red"}}>*</span> Except payroll rule , geo-fence and Break Rules</Typography> */}
        {/* </Grid> */}

        <Grid item md={12}>
          <Card
            style={{
              backgroundColor: cardBg,
              borderRadius: cardRadius,
              boxShadow: this.state.workschedule ? "-4px -4px 16px #999" : "",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} className="d-flex">
                  <Grid item md={11}>
                    <Typography
                      style={{ fontSize: 18, fontWeight: 700, color: "black" }}
                      gutterBottom
                    >
                      Work schedule
                    </Typography>
                  </Grid>
                  <Grid item md={1} className="editIcons">
                    <Tooltip arrow title="Edit" className="editIcon">
                      <IconButton
                        size="small"
                        onClick={() => this.openEditTableModal("workSchedule")}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 600, color: "black" }}
                    gutterBottom
                  >
                    Schedule Start Day
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography className={classes.Heading}>
                    <TextField
                      variant="outlined"
                      select
                      size="small"
                      style={{ width: 201, height: 40 }}
                      label="Select"
                      value={parseInt(this.state.dayChange)}
                      name="enforce_start_day"
                      disabled={this.state.workschedule === false}
                      onChange={(e) => this.handleChangeDay(e)}
                    >
                      {this.state.ScheduleDay.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 600, color: "black" }}
                    gutterBottom
                  >
                    Schedule TimeZone
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography className={classes.Heading}>
                    <Autocomplete
                      options={this.state.TimeZonedropDown}
                      getOptionLabel={(option) => option.label}
                      disabled={this.state.workschedule === false}
                      onChange={this.handleChangeDays}
                      value={
                        this.state.TimeZonedropDown.find(
                          (option) =>
                            option.value === this.state.location_timezone
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label="Select"
                          style={{ width: 201, height: 40 }}
                          disabled={this.state.workschedule === false}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              height: 40,
                              padding: "0 8px",
                            },
                          }}
                        />
                      )}
                    />
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <div style={{ padding: "16px 38px", overflowX: "auto" }}>
                    <Card className={classes.scheduleCard}>
                      {this.state.ScheduleDay.map((weekDay, index) => (
                        <div onClick={this.handlePopupModal} key={index}>
                          <Typography align="center" variant="h6">
                            {weekDay.label}
                          </Typography>
                          <div className={classes.divWdth}>
                            {new Array(24).fill("").map((item, i) => {
                              const inbtwn = this.rangeCalculater(
                                this.state.weekDaysTimingsData[index],
                                i
                              );

                              const isActive = inbtwn.inbtwnRange;
                              const isStart = inbtwn.startIndex?.index === i;
                              const isEnd = inbtwn.endIndex?.index === i;

                              const cls = `hour ${isActive ? "active" : ""} ${
                                isStart ? "start_ele" : ""
                              } ${isEnd ? "end_ele" : ""}`;

                              const totalHeight = 150;
                              const fixedBlockHeight = 14;
                              const remainingHeight =
                                totalHeight - 2 * fixedBlockHeight;
                              const activeBlockHeight = `${
                                remainingHeight / 20
                              }px`;
                              const inactiveBlockHeight = "4px";

                              const height =
                                isStart || isEnd
                                  ? `${fixedBlockHeight}px`
                                  : isActive
                                  ? activeBlockHeight
                                  : inactiveBlockHeight;

                              return (
                                <>
                                  <div
                                    key={i}
                                    className={cls}
                                    style={{
                                      borderTopLeftRadius:
                                        i === 0 ? "10px" : "0",
                                      borderTopRightRadius:
                                        i === 0 ? "10px" : "0",
                                      borderBottomLeftRadius:
                                        i === 23 ? "10px" : "0",
                                      borderBottomRightRadius:
                                        i === 23 ? "10px" : "0",
                                      margin: 0,
                                      // backgroundColor: "#4d72f8"
                                      backgroundColor: inbtwn.inbtwnRange
                                        ? "#4d72f8"
                                        : "#FFFFFF",
                                      height: height,
                                    }}
                                  >
                                    {inbtwn?.startIndex?.index === i && (
                                      <span
                                        style={{
                                          color: "white",
                                          fontSize: 8,
                                          display: "grid",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {inbtwn?.startIndex.value}
                                        {/* {i <= 12 ? " AM" : " PM"} */}
                                      </span>
                                    )}
                                    {inbtwn?.endIndex?.index === i && (
                                      <span
                                        style={{
                                          color: "white",
                                          fontSize: 8,
                                          display: "grid",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {inbtwn?.endIndex.value}
                                        {/* {i > 12 ? " PM" : " AM"} */}
                                      </span>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </Card>
                  </div>
                </Grid>
                {this.state.workschedule && (
                  <NewSaveSection
                    handleSubmit={this.handleSubmit}
                    handleCancel={() => {
                      this.closeEditModal("workSchedule");
                    }}
                  />
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12}>
          <Card
            style={{
              backgroundColor: cardBg,
              borderRadius: cardRadius,
              boxShadow: this.state.workRestriction
                ? "-4px -4px 16px #999"
                : "",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} className="d-flex">
                  <Grid item md={11}>
                    <Typography
                      style={{ fontSize: 18, fontWeight: 700, color: "black" }}
                      gutterBottom
                    >
                      Work Restrictions
                    </Typography>
                  </Grid>
                  <Grid item md={1} className="editIcons">
                    <Tooltip arrow title="Edit" className="editIcon">
                      <IconButton
                        size="small"
                        onClick={() =>
                          this.openEditTableModal("workRestrictions")
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                component="div"
                style={{
                  verticalAlign: "-webkit-baseline-middle",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                <Checkbox
                  name="early_clock_in_before_shift_start"
                  checked={
                    this.state.data.early_clock_in_before_shift_start
                      ? true
                      : false
                  }
                  disabled={this.state.workRestriction === false ? true : false}
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                <TextField
                  variant="outlined"
                  select
                  size="small"
                  disabled={this.state.workRestriction === false ? true : false}
                  value={
                    this.state.data.early_clock_in_before_shift_start_type === 1
                      ? 1
                      : 2
                  }
                  style={{
                    width: 90,
                    // marginTop: 4,
                    marginLeft: 10,
                    height: 40,
                    marginRight: 10,
                  }}
                  name="early_clock_in_before_shift_start_type"
                  onChange={this.handleChangeSelectValue}
                >
                  {this.state.BlockDropdownData.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                early clockin
                <FormControl variant="outlined" sx={{ m: 1, width: "25ch" }}>
                  <OutlinedInput
                    style={{
                      height: 40,
                      width: 50,
                      // marginTop: 13,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    onChange={this.handleChangeText}
                    name="early_clock_in_before_shift_start_minutes"
                    disabled={
                      this.state.workRestriction === false ? true : false
                    }
                    type="text"
                    value={
                      this.state.data.early_clock_in_before_shift_start_minutes
                    }
                  />
                </FormControl>
                minutes before the shift is scheduled to start
              </Typography>
              {/* <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    it is mandatory to upload selfie when clockin or clockout.
                                </Typography> */}

              {/* <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    Manager override is required to clock in before the break ends (On Tablet software only).
                                </Typography> */}

              <Typography
                variant="h6"
                component="div"
                style={{
                  verticalAlign: "-webkit-baseline-middle",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                <Checkbox
                  name="automatically_clock_out_employees"
                  checked={
                    this.state.data.automatically_clock_out_employees
                      ? true
                      : false
                  }
                  onChange={this.handleChangeDataThree}
                  disabled={this.state.workRestriction === false ? true : false}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Automatically clock out employees
                <FormControl variant="outlined" sx={{ m: 1, width: "25ch" }}>
                  <OutlinedInput
                    style={{
                      height: 40,
                      width: 50,
                      // marginTop: 13,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    value={
                      this.state.data.automatically_clock_out_employees_minutes
                    }
                    disabled={
                      this.state.workRestriction === false ? true : false
                    }
                    onChange={this.handleChangeText}
                    name="automatically_clock_out_employees_minutes"
                  />
                </FormControl>
                min after their schedule shift ends.Flag as Issue
                {this.state.workRestriction === true ? (
                  <Checkbox
                    style={{
                      color: schedulerColor.main,
                    }}
                    disabled={
                      this.state.workRestriction === false ? true : false
                    }
                    name="automatically_clock_out_employees_flag_issue"
                    checked={
                      this.state.data
                        .automatically_clock_out_employees_flag_issue
                        ? true
                        : false
                    }
                    onChange={this.handleChangeDataNewThree}
                  />
                ) : (
                  <Checkbox
                    style={{
                      color: schedulerColor.main,
                    }}
                    disabled={true}
                    name="automatically_clock_out_employees_flag_issue"
                    checked={
                      this.state.data
                        .automatically_clock_out_employees_flag_issue
                        ? true
                        : false
                    }
                    onChange={this.handleChangeDataNewThree}
                  />
                )}
              </Typography>

              <Typography
                variant="h6"
                component="div"
                style={{ fontWeight: 700, marginBottom: 10 }}
              >
                <Checkbox
                  name="mark_employee_as_late"
                  checked={this.state.data.mark_employee_as_late ? true : false}
                  disabled={this.state.workRestriction === false ? true : false}
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Mark employee as late
                <FormControl variant="outlined" sx={{ m: 1, width: "25ch" }}>
                  <OutlinedInput
                    style={{
                      height: 40,
                      width: 50,
                      // marginTop: 13,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    value={this.state.data.mark_employee_as_late_minutes}
                    onChange={this.handleChangeText}
                    disabled={
                      this.state.workRestriction === false ? true : false
                    }
                    type="text"
                    name="mark_employee_as_late_minutes"
                  />
                </FormControl>
                min after their schedule to start.Flag as Issue
                {this.state.workRestriction === true ? (
                  <Checkbox
                    style={{
                      color: schedulerColor.main,
                    }}
                    disabled={
                      this.state.data.mark_employee_as_late ? false : true
                    }
                    name="mark_employee_as_late_flag_issue"
                    checked={
                      this.state.data.mark_employee_as_late_flag_issue
                        ? true
                        : false
                    }
                    onChange={this.handleChangeDataNewOne}
                  />
                ) : (
                  <Checkbox
                    style={{
                      color: schedulerColor.main,
                    }}
                    disabled={true}
                    name="mark_employee_as_late_flag_issue"
                    checked={
                      this.state.data.mark_employee_as_late_flag_issue === 1
                        ? true
                        : false
                    }
                    onChange={this.handleChangeDataNewOne}
                  />
                )}
              </Typography>
              {this.state.workRestriction && (
                <SaveSection
                  handleSubmit={() => this.editworkRestriction()}
                  handleCancel={() => {
                    this.closeEditModal("workRestrictions");
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12}>
          <Card
            style={{
              backgroundColor: cardBg,
              borderRadius: cardRadius,
              boxShadow: this.state.Reminders ? "-4px -4px 16px #999" : "",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} className="d-flex">
                  <Grid item md={11}>
                    <Typography
                      style={{ fontSize: 18, fontWeight: 700, color: "black" }}
                      gutterBottom
                    >
                      Reminders
                    </Typography>
                  </Grid>
                  <Grid item md={1} className="editIcons">
                    <Tooltip arrow title="Edit" className="editIcon">
                      <IconButton
                        size="small"
                        onClick={() => this.openEditTableModal("Reminders")}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                component="div"
                style={{ fontWeight: 700, marginBottom: 10 }}
              >
                <Checkbox
                  name="clock_out_reminder_before_shift_end"
                  disabled={this.state.Reminders === false ? true : false}
                  checked={
                    this.state.data.clock_out_reminder_before_shift_end
                      ? true
                      : false
                  }
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Clock-out Reminder
                <FormControl variant="outlined" sx={{ m: 1, width: "25ch" }}>
                  <OutlinedInput
                    style={{
                      height: 40,
                      width: 50,
                      // marginTop: 13,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    value={
                      this.state.data
                        .clock_out_reminder_before_shift_end_minutes
                    }
                    disabled={this.state.Reminders === false ? true : false}
                    onChange={this.handleChangeText}
                    name="clock_out_reminder_before_shift_end_minutes"
                  />
                </FormControl>
                min before their schedule shift ends.
              </Typography>

              <div style={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontWeight: 700, marginBottom: 10 }}
                >
                  <Checkbox
                    name="clock_out_reminder_after_shift_end"
                    disabled={this.state.Reminders === false ? true : false}
                    checked={
                      this.state.data.clock_out_reminder_after_shift_end
                        ? true
                        : false
                    }
                    onChange={this.handleChangeData}
                    style={{
                      color: schedulerColor.main,
                    }}
                  />
                  Clock-out Reminder
                  <FormControl variant="outlined" sx={{ m: 1, width: "25ch" }}>
                    <OutlinedInput
                      style={{
                        height: 40,
                        width: 50,
                        // marginTop: 13,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                      value={
                        this.state.data
                          .clock_out_reminder_after_shift_end_minutes
                      }
                      onChange={this.handleChangeText}
                      disabled={this.state.Reminders === false ? true : false}
                      name="clock_out_reminder_after_shift_end_minutes"
                    />
                  </FormControl>
                  min after their schedule shift ends.
                </Typography>
                <div
                  style={{ display: "flex", marginLeft: "2%", marginTop: "1%" }}
                >
                  <Tooltip
                    arrow
                    title="Must be less than auto clock out duration if enabled, else it will not
                                            send reminder.Manager override is required to clock-in before the limit."
                  >
                    <InfoOutlinedIcon style={{ marginRight: 10 }} />
                    {/* <Typography>Must be less than auto clock out duration if enabled, else it will not
                                            send reminder. <br/> Manager override is required to clock-in before the limit.
                                        </Typography> */}
                  </Tooltip>
                </div>
              </div>

              {this.state.Reminders && (
                <SaveSection
                  handleSubmit={() => this.editReminders()}
                  handleCancel={() => {
                    this.closeEditModal("Reminders");
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* <Grid item md={12} >
                        <Card>
                            <CardContent>
                                <Typography variant="h6" component="div" style={{ fontWeight: 700 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enforce_prevent_early_clockin"
                                            checked={this.state.data.enforce_prevent_early_clockin ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Prevent early clock in
                                    <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                        <OutlinedInput
                                            style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                            value={this.state.data.enforce_prevent_early_clockin_min}
                                            onChange={this.handleChangeText}
                                            name="enforce_prevent_early_clockin_min"
                                            type="text"
                                        // style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                        // type="text"
                                        // value={this.state.data.alert_notify_manager_emp_not_clock_in_min}
                                        // onChange={this.handleChangeText}
                                        // name="alert_notify_manager_emp_not_clock_in_min"
                                        />
                                    </FormControl>
                                    minutes before the shift is scheduled to start

                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    it is mandatory to upload selfie when clockin or clockout.
                                </Typography>

                                <Typography variant="h6" component="div" style={{ fontWeight: 700 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enforce_prevent_early_clockin_breaks"
                                            checked={this.state.data.enforce_prevent_early_clockin_breaks ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Prevent early clock in from breaks.
                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    Manager override is required to clock in before the break ends (On Tablet software only).
                                </Typography>

                                <Typography variant="h6" component="div" style={{ fontWeight: 700 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enforce_clockout_reminder"
                                            checked={this.state.data.enforce_clockout_reminder ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Clock-out Reminder
                                    <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                        <OutlinedInput
                                            style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                            value={this.state.data.enforce_clockout_reminder_min}
                                            onChange={this.handleChangeText}
                                            name="enforce_clockout_reminder_min"
                                        />
                                    </FormControl>
                                    min after their schedule shift ends.
                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    Send text or push notifications to employee mobile apps if they have not clocked out after their shift has ended.
                                </Typography>

                                <Typography variant="h6" component="div" style={{ fontWeight: 700 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enforce_auto_clockout"
                                            checked={this.state.data.enforce_auto_clockout ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Automatically clock out employees
                                    <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                        <OutlinedInput
                                            style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                            value={this.state.data.enforce_auto_clockout_min}
                                            onChange={this.handleChangeText}
                                            name="enforce_auto_clockout_min"
                                        />
                                    </FormControl>
                                    min after their schedule shift ends.
                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46, marginTop: -10 }}>
                                    <span style={{ color: "red" }}>*Note:</span>{" "}must be less than auto clockout duration if enabled, else it will not send reminder
                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    Manager override is required to clock-in before the limit
                                </Typography>

                                <Typography variant="h6" component="div" style={{ fontWeight: 700 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enforce_auto_breakend"
                                            checked={this.state.data.enforce_auto_breakend ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Automatically break end employees
                                    <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                        <OutlinedInput
                                            style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                            value={this.state.data.automatically_end_breaks_employees}
                                            onChange={this.handleChangeText}
                                            type="text"
                                            name="automatically_end_breaks_employees"
                                        />
                                    </FormControl>
                                    min after their schedule break ends.
                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    Manager override is required to clock-in before the limit
                                </Typography>

                                <Typography variant="h6" component="div" style={{ fontWeight: 700 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enforce_notify_forgotten_clockout_time"
                                            checked={this.state.data.enforce_notify_forgotten_clockout_time ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Notify employees to add forgotten clock-out times via tickets.
                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46 }}>
                                    If we detect an employee has forgotten to clock out, we will send them a notification.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                     */}

        <Grid item md={12}>
          <Card
            style={{
              backgroundColor: cardBg,
              borderRadius: cardRadius,
              boxShadow: this.state.breaks ? "-4px -4px 16px #999" : "",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} className="d-flex">
                  <Grid item md={11}>
                    <Typography
                      style={{ fontSize: 18, fontWeight: 700, color: "black" }}
                      gutterBottom
                    >
                      Breaks
                    </Typography>
                  </Grid>
                  <Grid item md={1} className="editIcons">
                    <Tooltip arrow title="Edit" className="editIcon">
                      <IconButton
                        size="small"
                        onClick={() => this.openEditTableModal("Breaks")}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Typography
                style={{ fontSize: 14, fontWeight: 700, color: "black" }}
                gutterBottom
              >
                Save money and stay compliant by tracking breaks through
                Onevision.
              </Typography>
              {/* {this.state.allBreaks?.map((item, index) => {
                                    var date = item.created_at
                                    var FormatDate = moment(utcToLocal(date)).format("DD MMM YYYY,hh:mm A")
                                    return (
                                        <Grid container spacing={2}>
                                            <Grid style={{ display: "flex" }} item md={8}>
                                                <FormControlLabel
                                                    control={<Radio
                                                        name="status"
                                                        value={item._id}
                                                        checked={item._id === this.state.data.active_breakrule_id ? true : false}
                                                        onChange={(e) => this.handleChangeBreaksCheckbox(e, index)}
                                                        style={{
                                                            color: schedulerColor.main,
                                                        }} />}
                                                />
                                                <Typography style={{ color: "#232056", marginTop: 10 }}>
                                                    Employess Get a
                                                </Typography>
                                                <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                                    <OutlinedInput
                                                        disabled={this.state.breakss === false ? true : false}
                                                        name="break_Employees_get_minutes"
                                                        style={{ height: 20, width: 70, marginLeft: 10, marginTop: 10 }}
                                                        value={item.break_Employees_get_minutes}
                                                        onChange={(e) => this.handleChangeBreaksValue(e, index)}
                                                    />
                                                </FormControl>
                                                <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                    min
                                                </Typography>
                                                <TextField
                                                    disabled={this.state.breakss === false ? true : false}
                                                    variant="outlined"
                                                    select
                                                    size="small"
                                                    value={item.is_paid}
                                                    style={{ width: 90, marginLeft: 10, height: 20 }}
                                                    name="is_paid"
                                                    onChange={(e) => this.handleChangeBreaksValue(e, index)}
                                                >
                                                    {this.state.PaidDropdownData.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                    Break every
                                                </Typography>
                                                <FormControl>
                                                    <OutlinedInput
                                                        disabled={this.state.breakss === false ? true : false}
                                                        style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                                        value={item.break_hours}
                                                        name="break_hours"
                                                        onChange={(e) => this.handleChangeBreaksValue(e, index)}
                                                    />
                                                </FormControl>
                                                <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                    hours and it is
                                                </Typography>
                                                <TextField
                                                    disabled={this.state.breakss === false ? true : false}
                                                    variant="outlined"
                                                    select
                                                    size="small"
                                                    value={item.is_mendatory}
                                                    style={{ width: 130, marginLeft: 10, height: 20 }}
                                                    label="Select"
                                                    onChange={(e) => this.handleChangeBreaksValue(e, index)}
                                                    name="is_mendatory"
                                                >
                                                    {this.state.MandatoryDropdownData.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item md={4} style={{ display: "flex" }}>
                                                <Typography style={{ color: "#232056", marginTop: 10 }}>
                                                    Modified Date : {FormatDate}
                                                </Typography>
                                                <Button
                                                    size="small"
                                                    style={{ marginTop: -10 }}
                                                    // variant="outlined"
                                                    onClick={() => this.deleteRow(item)}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )
                                })} */}
              <Grid style={{ display: "flex", marginBottom: 10 }}>
                <Checkbox
                  disabled={this.state.breakss === false ? true : false}
                  name="isbreak_enable"
                  checked={this.state.data.isbreak_enable ? true : false}
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                <Typography
                  style={{ color: "#232056", marginTop: 10, width: 90 }}
                >
                  Start date
                </Typography>

                <div style={{ marginRight: 24 }} disable={this.state.disable}>
                  <MyDatePicker
                    // disablePast={true}
                    onClick={this.toggleDisable}
                    disabled={this.state.breakss === false ? true : false}
                    name="break_start_date"
                    label="Date"
                    onChange={(date) => {
                      this.handleChangeDate(date, "break_start_date");
                    }}
                    // error={""}
                    value={this.state.data.break_start_date}
                    placeholder="Enter Start Date"
                  />
                </div>
                <Typography
                  style={{ color: "#232056", marginTop: 10, width: 90 }}
                >
                  End date
                </Typography>
                <div style={{ marginRight: 24 }}>
                  <MyDatePicker
                    // disablePast={true}
                    name="break_end_date"
                    disabled={this.state.breakss === false ? true : false}
                    label="Date"
                    onChange={(date) => {
                      this.handleChangeDate(date, "break_end_date");
                    }}
                    // error={""}
                    value={this.state.data.break_end_date}
                    placeholder="Enter End Date"
                  />
                </div>
              </Grid>

              <Grid style={{ display: "flex", marginBottom: 20 }}>
                <Typography style={{ color: "#232056", marginTop: 10 }}>
                  Employess Get a
                </Typography>
                <FormControl variant="outlined" sx={{ m: 1, width: "25ch" }}>
                  <OutlinedInput
                    disabled={this.state.breakss === false ? true : false}
                    style={{
                      height: 40,
                      width: 70,
                      marginLeft: 10,
                      // marginTop: 10,
                    }}
                    value={this.state.data.break_Employees_get_minutes}
                    placeholder="30"
                    name="break_Employees_get_minutes"
                    onChange={this.handleChangeText}
                  />
                </FormControl>
                <Typography
                  style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}
                >
                  min
                </Typography>
                <TextField
                  variant="outlined"
                  select
                  size="small"
                  disabled={this.state.breakss === false ? true : false}
                  value={
                    this.state.data.break_Employees_get_count === 1 ? 1 : 2
                  }
                  style={{
                    width: 90,
                    // marginTop: 4,
                    marginLeft: 10,
                    height: 40,
                    marginRight: 10,
                  }}
                  name="break_Employees_get_count"
                  onChange={this.handleChangeSelectValue}
                >
                  {this.state.PaidDropdownData.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography
                  style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}
                >
                  Break every
                </Typography>
                <FormControl>
                  <OutlinedInput
                    disabled={this.state.breakss === false ? true : false}
                    style={{
                      height: 40,
                      width: 50,
                      // marginTop: 10,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    value={this.state.data.break_Employees_get_hour}
                    onChange={this.handleChangeText}
                    name="break_Employees_get_hour"
                  />
                </FormControl>
                <Typography
                  style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}
                >
                  hours and it is
                </Typography>
                <TextField
                  variant="outlined"
                  select
                  size="small"
                  disabled={this.state.breakss === false ? true : false}
                  value={this.state.data.isbreak_hours_type === 1 ? 1 : 2}
                  style={{
                    width: 90,
                    // marginTop: 4,
                    marginLeft: 10,
                    height: 40,
                    marginRight: 10,
                  }}
                  name="isbreak_hours_type"
                  onChange={this.handleChangeSelectValue}
                >
                  {this.state.MandatoryDropdownData.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                className={classes.viewSection}
                onClick={this.showPreviewBreak}
              >
                View Previous Break Rules
              </Grid>

              {this.state.viewModalOpen === true && (
                <ViewBreakModal
                  viewModalOpen={this.state.viewModalOpen}
                  handleViewModalClose={this.handleViewModalClose}
                  handleCheckboxAcknowledge={this.handleCheckboxAcknowledge}
                  isMustAknowledge_comment={this.state.isMustAknowledge_comment}
                  onDeleteBreak={this.onDeleteBreak}
                  isLoading={this.state.isLoading}
                  previewBreakData={this.state.previewBreakData}
                  handleSubmitBreak={this.handleSubmitBreak}
                />
              )}
              {/* {this.state.addBreaksData.map((item, index) => {
                                    return (
                                        <Grid style={{ display: "flex" }}>
                                            <FormControlLabel
                                                control={<Radio
                                                    disabled={this.state.breakss === false ? true : false}
                                                    name="status"
                                                    value={item.id}
                                                    checked={item.id === this.state.data.active_breakrule_id ? true : false}
                                                    onChange={(e) => this.handleChangeBreaksCheckbox(e, index)}
                                                    style={{
                                                        color: schedulerColor.main,
                                                    }} />}
                                            />
                                         <FormControlLabel
                                                control={<Checkbox
                                                    name="status"
                                                    checked={item.status}
                                                    onChange={(e) => this.handleNewStatusChange(e, index)}
                                                    style={{
                                                color: schedulerColor.main,
                                            }} />}
                                            />
                                            <Typography style={{ color: "#232056", marginTop: 10 }}>
                                                Employess Get a
                                            </Typography>
                                            <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                                <OutlinedInput
                                                    disabled={this.state.breakss === false ? true : false}
                                                    style={{ height: 20, width: 70, marginLeft: 10, marginTop: 10 }}
                                                    value={item.break_minutes}
                                                    placeholder="30"
                                                    name="break_minutes"
                                                    onChange={(e) => this.handleChangeNewBreaksValue(e, index)}
                                                />
                                            </FormControl>
                                            <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                min
                                            </Typography>
                                            <TextField
                                                disabled={this.state.breakss === false ? true : false}
                                                variant="outlined"
                                                select
                                                size="small"
                                                style={{ width: 90, marginLeft: 10, height: 20 }}
                                                label="Select"
                                                value={item.is_paid}
                                                name="is_paid"
                                                onChange={(e) => this.handleChangeNewBreaksValue(e, index)}
                                            >
                                                {this.state.PaidDropdownData.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                Break every
                                            </Typography>
                                            <FormControl>
                                                <OutlinedInput
                                                    disabled={this.state.breakss === false ? true : false}
                                                    style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                                    value={item.break_hours}
                                                    onChange={(e) => this.handleChangeNewBreaksValue(e, index)}
                                                    name="break_hours"
                                                />
                                            </FormControl>
                                            <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                hours and it is
                                            </Typography>
                                            <TextField
                                                disabled={this.state.breakss === false ? true : false}
                                                variant="outlined"
                                                select
                                                size="small"
                                                value={item.is_mendatory}
                                                style={{ width: 130, marginLeft: 10, height: 20 }}
                                                label="Select"
                                                onChange={(e) => this.handleChangeNewBreaksValue(e, index)}
                                                name="is_mendatory"
                                            >
                                                {this.state.MandatoryDropdownData.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )
                                })} */}
              {/* <Button className={classes.button} onClick={this.addNewRow}>
                                    Add New Break
                                </Button> */}
              {/* <Typography
                variant="h6"
                component="div"
                style={{ color: "black" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={this.state.breakss === false ? true : false}
                      name="paid_break_to_an_unpaid_break"
                      checked={
                        this.state.data.paid_break_to_an_unpaid_break
                          ? true
                          : false
                      }
                      onChange={this.handleChangeData}
                      style={{
                        color: schedulerColor.main,
                      }}
                    />
                  }
                />
                Convert excess time from a paid break to an unpaid break
              </Typography> */}

              <Typography
                variant="h6"
                component="div"
                style={{ fontWeight: 700 }}
              >
                <Checkbox
                  name="ending_break_earlier_than_break_settings"
                  checked={
                    this.state.data.ending_break_earlier_than_break_settings
                      ? true
                      : false
                  }
                  onChange={this.handleChangeData}
                  disabled={this.state.breakss === false ? true : false}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Prevent from ending the break earlier than break settings.
              </Typography>

              <Typography
                variant="h6"
                component="div"
                style={{ fontWeight: 700 }}
              >
                <Checkbox
                  name="automatically_end_breaks_employees"
                  checked={
                    this.state.data.automatically_end_breaks_employees
                      ? true
                      : false
                  }
                  disabled={this.state.breakss === false ? true : false}
                  onChange={this.handleChangeDataTwo}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Automatically end breaks for employees
                <FormControl variant="outlined" sx={{ m: 1, width: "25ch" }}>
                  <OutlinedInput
                    style={{
                      height: 40,
                      width: 50,
                      // marginTop: 13,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    value={
                      this.state.data.automatically_end_breaks_employees_minutes
                    }
                    onChange={this.handleChangeText}
                    disabled={this.state.breakss === false ? true : false}
                    type="text"
                    name="automatically_end_breaks_employees_minutes"
                  />
                </FormControl>
                min after their schedule break ends.Flag as Issue
                {this.state.breakss === true ? (
                  <Checkbox
                    style={{
                      color: schedulerColor.main,
                    }}
                    disabled={
                      this.state.data.automatically_end_breaks_employees
                        ? false
                        : true
                    }
                    name=" automatically_end_breaks_employees_flag_issue"
                    checked={
                      this.state.data
                        .automatically_end_breaks_employees_flag_issue
                        ? true
                        : false
                    }
                    onChange={this.handleChangeDataNewTwo}
                  />
                ) : (
                  <Checkbox
                    style={{
                      color: schedulerColor.main,
                    }}
                    disabled={true}
                    name=" automatically_end_breaks_employees_flag_issue"
                    checked={
                      this.state.data
                        .automatically_end_breaks_employees_flag_issue
                        ? true
                        : false
                    }
                    onChange={this.handleChangeDataNewTwo}
                  />
                )}
              </Typography>

              <Typography
                variant="h6"
                component="div"
                style={{ color: "black" }}
              >
                <Checkbox
                  disabled={this.state.breakss === false ? true : false}
                  name="automatically_apply_breaks_on_timecard"
                  checked={
                    this.state.data.automatically_apply_breaks_on_timecard
                      ? true
                      : false
                  }
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Automatically add breaks using break rule when the shift ends
              </Typography>
              {this.state.breakss && (
                <SaveSection
                  handleSubmit={this.editBreaks}
                  handleCancel={() => {
                    this.closeEditModal("Breaks");
                  }}
                />
              )}
            </CardContent>
          </Card>

          <DeleteModal
            bgColor={schedulerColor.main}
            desc="Are you sure you want to delete?"
            open={this.state.deleteModal}
            onClose={this.deleteModalClose}
            handleDelete={this.deleteData}
          />
        </Grid>

        <Grid item md={12}>
          <Card
            style={{
              backgroundColor: cardBg,
              borderRadius: cardRadius,
              boxShadow: this.state.alertsAndNotification
                ? "-4px -4px 16px #999"
                : "",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} className="d-flex">
                  <Grid item md={11}>
                    <Typography
                      style={{ fontSize: 18, fontWeight: 700, color: "black" }}
                      gutterBottom
                    >
                      Alerts & Notifications
                    </Typography>
                  </Grid>
                  <Grid item md={1} className="editIcons">
                    <Tooltip arrow title="Edit" className="editIcon">
                      <IconButton
                        size="small"
                        onClick={() =>
                          this.openEditTableModal("alertsAndNotification")
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="h6" component="div">
                <Checkbox
                  name="manager_approval_for_availability_requests"
                  disabled={
                    this.state.alertsAndNotification === false ? true : false
                  }
                  checked={
                    this.state.data.manager_approval_for_availability_requests
                      ? true
                      : false
                  }
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Require manager approval for employee availability requests.
              </Typography>

              <Typography variant="h6" component="div">
                <Checkbox
                  name="employees_create_correction_requests"
                  disabled={
                    this.state.alertsAndNotification === false ? true : false
                  }
                  checked={
                    this.state.data.employees_create_correction_requests
                      ? true
                      : false
                  }
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Enable employees to create correction request.
              </Typography>

              <Typography variant="h6" component="div">
                <Checkbox
                  name="shift_feedback_after_end"
                  disabled={
                    this.state.alertsAndNotification === false ? true : false
                  }
                  checked={
                    this.state.data.shift_feedback_after_end ? true : false
                  }
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Ask employees for shift feedback at the end of their shift.
              </Typography>
              {this.state.alertsAndNotification && (
                <SaveSection
                  handleSubmit={() => this.editAlertsAndNotification()}
                  handleCancel={() => {
                    this.closeEditModal("alertsAndNotification");
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            item
            md={7}
            className={classes.flex}
            style={{ display: "none" }}
          >
            <Grid className={classes.flex}>
              <Grid
                style={{
                  background: "#005F73",
                  height: 10,
                  width: 10,
                }}
              />
              <Typography
                style={{ lineHeight: "12px" }}
                className={classes.HeadCell}
              >
                Events & Trades
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} style={{ display: "none" }}>
          <Card>
            <CardContent>
              <Typography
                style={{ fontSize: 14, fontWeight: 700 }}
                color="text.secondary"
                gutterBottom
              >
                Display special events and days on the schedule.
              </Typography>
              <Typography
                variant="h6"
                component="div"
                style={{ fontWeight: 700 }}
              >
                <Checkbox
                  name="schedule_history"
                  checked={this.state.data.schedule_history ? true : false}
                  onChange={this.handleChangeData}
                  style={{
                    color: schedulerColor.main,
                  }}
                />
                Schedule History
              </Typography>
              <Typography
                variant="h6"
                component="div"
                style={{ marginLeft: 46 }}
              >
                View the history of all your published schedules. On Onevision
                you can track every update to your schedule and know who saw
                changes.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerSettingsLocationdata.isLoading,
  LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
  WorkSchedule: state.schedulerSettingsLocationdata.WorkSchedule,
});

const ConnectWith = connect(mapStateToProps, {
  getScheduleData,
  updateScheduleData,
})(Scheduling);
export default withStyles(styles)(ConnectWith);
