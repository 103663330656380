import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { no_permission } from "../icons";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    overflowX: "hidden",
    padding: "0 2rem",
  },
  content: {
    paddingTop: 0,
    textAlign: "center",
  },
  image: {
    marginBottom: 50,
    display: "inline-block",
    maxWidth: "50%",
    width: "50%",
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item sm={6} xs={12}>
          <div className={classes.content}>
            <img alt="" className={classes.image} src={no_permission} />
            <Typography
              variant="h2"
              style={{ marginBottom: "2rem", color: "black" }}
            >
              Sorry, You Are Not Allowed to Access This Page
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
