import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  LoadingData,
  DeleteModal,
  MyDatePicker,
} from "../../../components";
import { connect } from "react-redux";
import { schedulerColor } from "../../../config";
import { alert } from "../../../utilities";
import moment, { weekdays } from "moment";
import Backdrop from "../../../components/Backdrop";
import Modal from "../../../components/Modal";
import TimeZone from "../Settings/Timezone";
import { settingsServices } from "../Settings/modules/services";
import EditIcon from "@mui/icons-material/Edit";
import SaveSection from "../Settings/SaveSection";
import { MyAvailabilityService } from "./modules/services";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CardContent,
  MenuItem,
  Typography,
  FormControl,
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  OutlinedInput,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ViewBreakModal from "../Settings/ViewBreakModal";
import CollapsedTableMainAvailability from "./CollapsedTableMainAvailability";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  HeadCell: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#232056",
    marginLeft: 10,
  },
  buttonMain: {
    textAlign: "right",
    fontWeight: 700,
  },
  button: {
    background: "#005F73",
    borderRadius: 8,
    color: "white",
    padding: 10,

    "&:hover": {
      background: "#005F73",
      borderRadius: 8,
    },
  },
  flex: {
    display: "flex",
    margin: "20px",
  },
  Heading: {
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  HeadingValue: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "36px",
  },
  card: {
    //   margin: '15px 0px 0px 0px',
    borderRadius: 8,
    background: "#FFFFFF",
    //   boxShadow: '0 0 0.5em #E2E2E2',
    boxShadow: "0 12.5214px 16.9039px rgb(0 0 0 / 5%)",
  },
  scheduleCard: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  divWdth: {
    background: "#fff",
    border: "1px solid rgba(161, 161, 161, 0.5)",
    width: 100,
    borderRadius: "6px",
    cursor: "pointer",
  },
  divWdths: {
    background: "#4D72F8",
    cursor: "pointer",
    color: "white",
    marginTop: 26,
    padding: 7,
    width: "122%",
    marginLeft: -8,
    height: 80,
  },
  time: {
    marginTop: 10,
    fontFamily: "Calibri",
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
    marginTop: -12,
  },
  viewSection: {
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
});

export function fetchNewTeamAvailabilityData() {
  window.NewTeamAvailability.fetchData();
}

class TeamAvailability extends Component {
  constructor(props) {
    super(props);
    window.NewTeamAvailability = this;
    this.state = {
      displayBusinessLocations: true,
      getbusinessData: [],
      disable: true,
      isWeek: false,
      myItem: {},
      isChecked: false,
      order: "asc",
      orderBy: "id",
      searchUsers: "",
      isExpanded: true,
      headCells: [
        { id: "user_name", label: "User Name", numeric: false },
        { id: "currentDate", label: "Current Date", numeric: false },
        { id: "location_name", label: "Location", numeric: false },
        { id: "manager_status", label: "Status", numeric: false },
        { id: "created_at", label: "Created At", numeric: false },
      ],
      flageed: [
        { value: 1, label: "Flagged" },
        { value: 0, label: "Blocked" },
      ],
      applyToAll: 0,
      workschedule: false,
      workRestriction: false,
      Reminders: false,
      breakss: false,
      alertsAndNotification: false,
      emoji: 1,
      anchorEl: null,
      data: {},
      allBreaks: [],
      addBreaksData: [],
      location_timezone: "",
      deleterowId: "",
      getApiResponse: "",
      scheduler_lid: cookies.get("scheduler_lid"),
      break_start_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      break_end_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      isBreakUpdate: 0,
      viewModalOpen: false,
      isMustAknowledge_comment: false,
      ScheduleDay: [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 0, label: "Sunday" },
      ],
      weekDaysTimingsData: [
        {
          day: "Monday",
          schedule_start_day: 1,
          schedule_day_start_time: "",
          schedule_day_end_time: "",
        },
      ],
    };
  }

  handleClickToggle = (open, index) => {
    const newData = this.state.getApiResponse?.user_team.map(
      (items, newIndex) => {
        const cloneItem = items;
        if (index === newIndex) {
          cloneItem["open"] = open ? false : true;
        } else {
          cloneItem["open"] = false;
        }

        return cloneItem;
      }
    );

    this.setState((prevState) => ({
      getApiResponse: {
        ...prevState.getApiResponse,
        user_team: newData,
      },
    }));
  };

  componentDidMount = () => {
    if (this.props.permissionsData?.scheduler_team_availability) {
      this.fetchData();
    }
  };

  fetchData = () => {
    var data = {
      lid: this.props.scheduler_lid,
    };
    this.setState({ isLoading: true });
    MyAvailabilityService.getTeamAvailabilityData(data.lid).then((response) => {
      if (response.data.success === 2) {
      } else {
        this.setState(
          {
            getbusinessData: response.data.businessSchedule,
            getApiResponse: response.data,
            isLoading: false,
          },
          () => {
            if (this.state.getbusinessData) {
              const newWeekDaysTimingsData = [
                ...this.state.getbusinessData,
              ].map((item) => {
                var cloneItem = item;
                var schedule_day_start_time = moment(
                  item.schedule_day_start_time
                )
                  .tz(item.schedule_time_zone)
                  .format("HH:mm");
                var schedule_day_end_time = moment(item.schedule_day_end_time)
                  .tz(item.schedule_time_zone)
                  .format("HH:mm");
                cloneItem["schedule_day_start_time"] = schedule_day_start_time;
                cloneItem["schedule_day_end_time"] = schedule_day_end_time;
                return cloneItem;
              });
              this.setState({
                weekDaysTimingsData: newWeekDaysTimingsData,
              });
            }
          }
        );
      }
    });
  };

  handleClickToggleInside = (open, index, id, av_id) => {
    var newData = [];
    this.state.getApiResponse?.user_team.map((bb) => {
      if (bb.effective_dates) {
        bb.effective_dates.map((ff) => {
          newData.push(ff);
        });
      }
    });
    var tempDate = newData;
    tempDate.map((inner) => {
      if (inner.av_id === av_id) {
        inner["openInside"] = open ? false : true;
      } else {
        inner["openInside"] = false;
      }
    });
    newData["effective_dates"] = tempDate;
    var myAvaData = this.state.getApiResponse?.user_team.map((myITems) =>
      myITems.lid === newData.lid ? { ...newData } : myITems
    );

    this.setState((prevState) => ({
      getApiResponse: {
        ...prevState.getApiResponse,
        user_team: myAvaData,
      },
    }));
  };

  // onChangeSetAsAllWeeks = (e) => {
  //   this.setState({ isWeek: !this.state.isWeek });
  //   if (e.target.checked) {
  //     var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
  //       const openValue =
  //         this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
  //       const closeValue =
  //         this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
  //       var cloneItem = item;
  //       cloneItem["schedule_day_start_time"] = openValue;
  //       cloneItem["schedule_day_end_time"] = closeValue;
  //       return cloneItem;
  //     });
  //     this.setState({
  //       weekDaysTimingsData: newRoutes,
  //     });
  //   } else {
  //     var newRoutes = [...this.state.weekDaysTimingsData].map(
  //       (item, myIndex) => {
  //         const openValue =
  //           this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
  //         const closeValue =
  //           this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
  //         var cloneItem = item;
  //         cloneItem["schedule_day_start_time"] = "";
  //         cloneItem["schedule_day_end_time"] = "";
  //         if (myIndex === 0) {
  //           cloneItem["schedule_day_start_time"] = openValue;
  //           cloneItem["schedule_day_end_time"] = closeValue;
  //         }
  //         return cloneItem;
  //       }
  //     );
  //     this.setState({
  //       weekDaysTimingsData: newRoutes,
  //     });
  //   }
  // };

  // handleChangeWeekTiming = (e, isOpen, index) => {
  //   var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
  //     var cloneItem = item;
  //     if (index === myIndex) {
  //       cloneItem[isOpen] = e.value;
  //     }
  //     return cloneItem;
  //   });
  //   this.setState({
  //     weekDaysTimingsData: newRoutes,
  //   });
  // };

  handleChange = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  // componentDidUpdate(prevState) {
  //   if (this.state.getbusinessData !== prevState.getbusinessData) {
  //     if (this.state.getbusinessData) {
  //       const newWeekDaysTimingsData = [
  //         ...this.state.getbusinessData,
  //       ].map((item) => {
  //         var cloneItem = item;
  //         var schedule_day_start_time = moment(item.schedule_day_start_time)
  //           .tz(item.schedule_time_zone)
  //           .format("HH:mm");
  //         var schedule_day_end_time = moment(item.schedule_day_end_time)
  //           .tz(item.schedule_time_zone)
  //           .format("HH:mm");
  //         cloneItem["schedule_day_start_time"] = schedule_day_start_time;
  //         cloneItem["schedule_day_end_time"] = schedule_day_end_time;
  //         return cloneItem;
  //       });
  //       this.setState({
  //         weekDaysTimingsData: newWeekDaysTimingsData,
  //       });
  //     }
  //     this.setState({
  //       isLoading: false,
  //       // location_timezone:
  //       //   this.props.LocationDetaildata.locationDetail.location_timezone,
  //       // data: this.props.LocationDetaildata.locationDetail,
  //     });
  //   }
  // }

  rangeCalculater = (date, index) => {
    if (date === undefined || date === null) {
      return false;
    }
    const startRange =
      date.schedule_day_start_time !== ""
        ? Number(date.schedule_day_start_time.split(":")[0])
        : null;
    const endRange =
      date.schedule_day_end_time !== ""
        ? Number(date.schedule_day_end_time.split(":")[0])
        : null;
    if (startRange === null || endRange === null) {
      return false;
    }
    const inbtwnRange = index >= startRange && index <= endRange ? true : false;
    return {
      inbtwnRange: inbtwnRange,
      startIndex: {
        index: startRange,
        value: date.schedule_day_start_time,
      },
      endIndex: {
        index: endRange,
        value: date.schedule_day_end_time,
      },
    };
  };
  // rangeCalculater = (date, index) => {
  //   console.log("hfhkvjfkvmf", date)
  //   if (date === undefined || date === null) {
  //     return false;
  //   }

  //   var formatStartTemp =
  //     new Date(
  //       date.schedule_day_start_time
  //     );
  //   var formatStartData =
  //     formatStartTemp.toLocaleString(
  //       "en-US"
  //     );

  //   var formatEndTemp =
  //     new Date(
  //       date.schedule_day_end_time
  //     );
  //   var formatEndData =
  //     formatEndTemp.toLocaleString(
  //       "en-US"
  //     );

  //   var formatStart = moment(
  //     formatStartData,
  //     "MM/DD/YYYY HH:mm:ss A"
  //   ).format("HH");
  //   console.log("hfhkvjfkvmf", formatStart)
  //   var formatEnd = moment(
  //     formatEndData,
  //     "MM/DD/YYYY HH:mm:ss A"
  //   ).format("HH");
  //   var formatStartNew = moment(
  //     formatStartData,
  //     "MM/DD/YYYY HH:mm:ss A"
  //   ).format("HH:mm");
  //   var formatEndNew = moment(
  //     formatEndData,
  //     "MM/DD/YYYY HH:mm:ss A"
  //   ).format("HH:mm");
  //   const startRange =
  //     formatStart !== "" ? Number(formatStart.split(":")[0]) : null;
  //   const endRange = formatEnd !== "" ? Number(formatEnd.split(":")[0]) : null;

  //   if (startRange === null || endRange === null) {
  //     return false;
  //   }
  //   const inbtwnRange = index >= startRange && index <= endRange ? true : false;
  //   console.log("hfhkvjfkvmf", inbtwnRange)

  //   return {
  //     inbtwnRange: inbtwnRange,
  //     startIndex: {
  //       index: startRange,
  //       value: formatStartNew,
  //     },
  //     endIndex: {
  //       index: endRange,
  //       value: formatEndNew,
  //     },
  //   };
  // };

  handlePopupModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  handleSubmit = async () => {
    var tempArray = this.state.weekDaysTimingsData.map((item, index) => {
      var timeZoneSplit = this.state.location_timezone.split("|").pop();
      let initialdate = moment().format("YYYY-MM-DD");
      let start_time =
        initialdate +
        "T" +
        item.schedule_day_start_time +
        ":00" +
        timeZoneSplit;
      let datetimeA = start_time;
      let initialdateEnd = moment().format("YYYY-MM-DD");
      let end_time =
        initialdateEnd +
        "T" +
        item.schedule_day_end_time +
        ":00" +
        timeZoneSplit;
      let datetimeB = end_time;

      // var splitdata = timeZoneSplit[0];
      // var timezone = moment(datetimeA).tz(splitdata).format("YYYY-MM-DDTHH:mm:ssZ");
      // var timezone_end = moment(datetimeB).tz(splitdata).format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc = moment(timezone).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc_end = moment(timezone_end).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      var newObj = {
        schedule_start_day: index,
        schedule_day_start_time: datetimeA,
        schedule_day_end_time: datetimeB,
        schedule_time_zone: this.state.location_timezone,
      };
      return newObj;
    });

    var data = {
      lid: this.props.scheduler_lid,
      same_for_all_days: this.state.isWeek,
      DaysArr: tempArray,
    };

    settingsServices.addWorkScheduler(data).then((response) => {
      if (response.data.success === 2) {
        alert.error(response.data.message);
      } else {
        // this.setState({
        if (this.state.workschedule === true) {
          this.setState({
            workschedule: false,
          });
        }
        // })
        // this.props.getScheduleData(this.props.scheduler_lid,data)
        alert.success(response.data.message);
      }
    });
  };

  approveRejectStatus = (av_id, items, status) => {
    var data = {
      av_id: av_id,
      user_notes: "",
      lid: items.lid,
      uid: items.uid,
      avail_status: status,
    };
    MyAvailabilityService.approveRejectStatus(data).then((response) => {
      if (response.data.success === 2) {
        alert.success(response.data.message);
      } else {
        alert.success(response.data.message);
        this.fetchData();
      }
    });

    // this.setState({
    //   isLoading: false
    // })
  };
  render() {
    const cardBg = "#faf9f9";
    const cardRadius = "12px";
    const { permissionsData, classes } = this.props;

    if (this.state.isLoading) return <LoadingData />;

    return permissionsData.scheduler_team_availability ? (
      <Grid container spacing={2} className={classes.root}>
        {/* {this.state.openModal && (
          <div>
            <Backdrop handlePopupModal={this.handlePopupModal} />
            <Modal title="TimeZone" handlePopupModal={this.handlePopupModal}>
              <div style={{ width: 850 }}>
                <TimeZone
                  isWeek={this.state.isWeek}
                  onChangeSetAsAllWeeks={this.onChangeSetAsAllWeeks}
                  handlePopupModal={this.handlePopupModal}
                  daysData={this.state.weekDaysTimingsData}
                  handleChangeWeekTiming={this.handleChangeWeekTiming}
                  handleSubmit={this.handleSubmit}
                />
              </div>
            </Modal>
          </div>
        )} */}

        <Grid item md={12}>
          <Grid
            style={{
              marginBottom: "1.7rem",
            }}
            container
            spacing={2}
          >
            <Grid item md={12}>
              <Accordion
                expanded={this.state.isExpanded}
                onChange={this.handleChange}
              >
                <AccordionSummary
                  style={{
                    backgroundImage: schedulerColor.main,
                    justifyContent: "center",
                    display: "flex",
                    minHeight: "30px",
                  }}
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    variant="h5"
                    style={{ color: "white", justifyContent: "center" }}
                  >
                    Business Hours
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item md={12} className="d-flex">
                    <Grid item md={12}>
                      <div>
                        <div className={classes.scheduleCard}>
                          {this.state.getbusinessData &&
                            this.state.getbusinessData.map((weekDay, index) => (
                              <div
                                //onClick={this.handlePopupModal}
                                key={index}
                                style={{ height: "150px" }}
                              >
                                <div className={classes.divWdth}>
                                  {new Array(24).fill("").map((item, i) => {
                                    const inbtwn = this.rangeCalculater(
                                      this.state.getbusinessData[index],
                                      i
                                    );
                                    const cls =
                                      "hour" +
                                      " " +
                                      `${
                                        inbtwn.inbtwnRange
                                          ? `active ${i === 0 && "start_ele"} ${
                                              i === 23 && "end_ele"
                                            }`
                                          : ""
                                      }`;

                                    const isActive = inbtwn.inbtwnRange;
                                    const isStart =
                                      inbtwn.startIndex?.index === i;
                                    const isEnd = inbtwn.endIndex?.index === i;

                                    const totalHeight = 150;
                                    const fixedBlockHeight = 14; // Fixed height for start/end elements
                                    const remainingHeight =
                                      totalHeight - 2 * fixedBlockHeight;
                                    const activeBlockHeight = `${
                                      remainingHeight / 20
                                    }px`; // Adjust for active blocks
                                    const inactiveBlockHeight = "4px"; // Adjust for inactive blocks

                                    const height =
                                      isStart || isEnd
                                        ? `${fixedBlockHeight}px`
                                        : isActive
                                        ? activeBlockHeight
                                        : inactiveBlockHeight;

                                    return (
                                      <>
                                        <div
                                          className={cls}
                                          style={{
                                            borderTopLeftRadius:
                                              i === 0 ? "10px" : "0",
                                            borderTopRightRadius:
                                              i === 0 ? "10px" : "0",
                                            borderBottomLeftRadius:
                                              i === 23 ? "10px" : "0",
                                            borderBottomRightRadius:
                                              i === 23 ? "10px" : "0",
                                            margin: 0,
                                            height,
                                            // backgroundColor: "#4d72f8"
                                            backgroundColor: inbtwn.inbtwnRange
                                              ? "rgba(170, 7, 107, 0.1)"
                                              : "#FFFFFF",
                                          }}
                                        >
                                          {inbtwn?.startIndex?.index === i && (
                                            <span
                                              style={{
                                                color: "#000",
                                                fontSize: 8,
                                                display: "grid",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {inbtwn?.startIndex.value}
                                              {i <= 12 ? " AM" : " PM"}
                                            </span>
                                          )}
                                          {inbtwn?.endIndex?.index === i && (
                                            <span
                                              style={{
                                                color: "#000",
                                                fontSize: 8,
                                                display: "grid",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {inbtwn?.endIndex.value}
                                              {i > 12 ? " PM" : " AM"}
                                            </span>
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>

                                <Typography
                                  style={{ marginBottom: "0.4rem" }}
                                  align="center"
                                  variant="h6"
                                >
                                  {weekDay.schedule_start_day === 0
                                    ? "Sunday"
                                    : weekDay.schedule_start_day === 1
                                    ? "Monday"
                                    : weekDay.schedule_start_day === 2
                                    ? "Tuesday"
                                    : weekDay.schedule_start_day === 3
                                    ? "Wednesday"
                                    : weekDay.schedule_start_day === 4
                                    ? "Thrusday"
                                    : weekDay.schedule_start_day === 5
                                    ? "Friday"
                                    : weekDay.schedule_start_day === 6
                                    ? "Saturday"
                                    : ""}
                                </Typography>
                              </div>
                            ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          {/* </CardContent>
          </Card> */}
        </Grid>

        <Grid item md={12}>
          <CollapsedTableMainAvailability
            handleClickToggle={this.handleClickToggle}
            handleClickToggleInside={this.handleClickToggleInside}
            scheduler_lid={this.state.scheduler_lid}
            getApiResponse={this.state.getApiResponse}
            permissionsData={permissionsData}
            approveRejectStatus={this.approveRejectStatus}
            newModalOpen={this.newModalOpen}
            timezone={this.props.timezone}
            isLoading={this.state.isLoading}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
  WorkScheduleData: state.schedulerSettingsLocationdata.WorkScheduleData,
  WorkSchedule: state.schedulerSettingsLocationdata.WorkSchedule,
});

const ConnectWith = connect(mapStateToProps, {})(TeamAvailability);
export default withStyles(styles)(ConnectWith);
