import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from "../../../config";
import {
  MySelectField,
  MyAutocomplete,
  BasicTimePicker,
  LoadingData,
} from "../../../components";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  TextField,
  Grid,
  ButtonGroup,
  Button,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  avatarDay: {
    cursor: "pointer",
    float: "left",
    // marginRight: '5px',
    textTransform: "uppercase",
    height: "35px",
    width: "35px",
    fontWeight: 800,
    fontSize: "10px",
    color: "#ffffff",
  },
  avatarText: {
    float: "left",
    textTransform: "uppercase",
    height: "35px",
    width: "35px",
    fontWeight: 800,
    fontSize: "15px",
    backgroundColor: "#DFF2F7",
    color: "#8A5EA6",
  },
  userName: {
    color: schedulerColor.main,
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "3px",
    lineHeight: "2.1rem",
    display: "inline",
    float: "left",
    cursor: "move",
  },
  toggle: {
    // thumbOnColor: schedulerColor.main,
    // trackOnColor: schedulerColor.main
    "& .MuiSwitch-switchBase": {
      color: schedulerColor.main,
    },
  },
});

class AddEditMYAV extends Component {
  render() {
    const { classes, myItem, errors, errorsHelper, isAddLoading } = this.props;

    return isAddLoading ? (
      <Grid container spacing={2} className={classes.root}>
        <LoadingData />
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid
          item
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
          xs={12}
        >
          <Grid item md={6} xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="is_unavailable"
                    checked={
                      myItem.is_unavailable === 1 || myItem.is_wholeday === 1
                    }
                    onChange={this.props.handleChangePrefered}
                    disabled={myItem.is_wholeday}
                  />
                }
                label="Unavailable"
              />
            </FormGroup>
          </Grid>
          <Grid item style={{ textAlign: "end" }} md={6} xs={6}>
            <FormControlLabel
              control={
                <Switch
                  // style={{ color: schedulerColor.main }}
                  className={classes.toggle}
                  checked={myItem.is_wholeday}
                  onChange={this.props.handleChangeWholeDay}
                  name="is_wholeday"
                />
              }
              label="Unavailable Whole Day"
            />
          </Grid>
        </Grid>

        <Grid item sm={6} xs={12}>
          <BasicTimePicker
            label="Start Time"
            disabled={
              myItem.is_wholeday === 1 || myItem.is_unavailable === 0
                ? true
                : false
            }
            key={`dateTime-${this.props.timezone[0].location_timezone}`}
            name="unavailable_start_time"
            value={
              myItem?.unavailable_start_time
                ? dayjs(myItem?.unavailable_start_time)
                : dayjs(new Date())
            }
            onChange={(date) => {
              this.props.handleChangeStartTimeUnavailable(
                date,
                "unavailable_start_time"
              );
            }}
            // onChange={this.props.handleChangeStartTimeUnavailable}
            onFocus={this.props.handleFocus}
            error={errors.unavailable_start_time}
            helperText={
              errors?.unavailable_start_time
                ? errorsHelper.unavailable_start_time
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={
                  errors.unavailable_start_time
                    ? errorsHelper.unavailable_start_time
                    : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <BasicTimePicker
            label="End Time"
            disabled={
              myItem.is_wholeday === 1 || myItem.is_unavailable === 0
                ? true
                : false
            }
            key={`dateTime-${this.props.timezone[0].location_timezone}`}
            name="unavailable_end_time"
            value={
              myItem?.unavailable_end_time
                ? dayjs(myItem?.unavailable_end_time)
                : dayjs(new Date())
            }
            onChange={(date) => {
              this.props.handleChangeEndTimeUnavailable(
                date,
                "unavailable_end_time"
              );
            }}
            // onChange={this.props.handleChangeendTimeUnavailable}
            onFocus={this.props.handleFocus}
            error={errors.unavailable_end_time}
            helperText={
              errors?.unavailable_end_time
                ? errorsHelper.unavailable_end_time
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={
                  errors.unavailable_end_time
                    ? errorsHelper.unavailable_end_time
                    : ""
                }
              />
            )}
          />
          <Typography style={{ color: "red" }}>
            {this.props.nextDayUn ? "Next Day" : ""}
          </Typography>
          <Typography style={{ color: "red" }}>
            {errors.unavailable_end_time
              ? errorsHelper.unavailable_end_time
              : ""}
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name="is_preferred"
                  checked={myItem.is_preferred === 1 ? true : false}
                  onChange={this.props.handleChangePrefered}
                  disabled={myItem.is_wholeday === 1 ? true : false}
                  // disabled={myItem.is_preferred === 0 ? false : true}
                />
              }
              label="Preferred"
            />
          </FormGroup>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Typography>{myItem.dayName}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <BasicTimePicker
            label="Start Time"
            disabled={
              myItem.is_wholeday === 1 || myItem.is_preferred === 0
                ? true
                : false
            }
            key={`dateTime-${this.props.timezone[0].location_timezone}`}
            name="prefered_start_time"
            value={
              myItem?.prefered_start_time
                ? dayjs(myItem?.prefered_start_time)
                : dayjs(new Date())
            }
            onChange={(date) => {
              this.props.handleChangeStartTimePrefered(
                date,
                "prefered_start_time"
              );
            }}
            onFocus={this.props.handleFocus}
            error={errors.prefered_start_time}
            helperText={
              errors?.prefered_start_time
                ? errorsHelper.prefered_start_time
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={
                  errors.prefered_start_time
                    ? errorsHelper.prefered_start_time
                    : ""
                }
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <BasicTimePicker
            label="End Time"
            disabled={
              myItem.is_wholeday === 1 || myItem.is_preferred === 0
                ? true
                : false
            }
            key={`dateTime-${this.props.timezone[0].location_timezone}`}
            name="prefered_end_time"
            value={
              myItem?.prefered_end_time
                ? dayjs(myItem?.prefered_end_time)
                : dayjs(new Date())
            }
            onChange={(date) => {
              this.props.handleChangeEndTimePrefered(date, "prefered_end_time");
            }}
            // onChange={this.props.handleChangeendTimeUnavailable}
            onFocus={this.props.handleFocus}
            error={errors.prefered_end_time}
            helperText={
              errors?.prefered_end_time ? errorsHelper.prefered_end_time : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={
                  errors.prefered_end_time ? errorsHelper.prefered_end_time : ""
                }
              />
            )}
          />
          <Typography style={{ color: "red" }}>
            {this.props.nextDayPref ? "Next Day" : ""}
          </Typography>
          <Typography style={{ color: "red" }}>
            {errors.prefered_end_time ? errorsHelper.prefered_end_time : ""}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AddEditMYAV);
